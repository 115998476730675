import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados       : [],
    paginas     : 0,
    filtro: {
      // Filtro na Tela de Consulta --------
      cliente_nome        : null,
      cod_corretor        : null,
      corretor            : null,
      cod_empreendimento  : null,
      empreendimento      : null,
      data_inicial        : null,
      data_final          : null,
    }
  }
}

var vm_store_Leads = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async LeadsGet(p_params) {
      const resposta = await API.get("crm/negociacoes/leads", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result
        }
        else
          return [];
      }
    },

  }
    
})

export default vm_store_Leads
